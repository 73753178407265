<template>
    <v-container fluid>
        <v-card flat class="mr-3" :loading="loading">
            <v-card-title class="headline" v-if="lead != null">
                {{ lead.full_name }}

                <v-spacer></v-spacer>

                <v-chip label :color="lead.contacted == 1 ? 'success' : 'warning'">{{ lead.contacted == 1 ? 'Contacted' : 'Not contacted' }}</v-chip>
            </v-card-title>
            <v-card-subtitle v-if="lead != null">
                <strong>Created on</strong>: {{ formatDate( lead.date_created ) }}
            </v-card-subtitle>
            <v-card-text v-if="lead != null" class="mt-5">
                <v-row>
                    <v-col cols="6">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <div class="subtitle-1 font-weight-light mb-5">
                                    General Information
                                </div>

                                <div class="item">
                                    <div class="value">
                                        {{ formatPhoneNum( lead.phone ) }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.phone)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Phone</div>
                                </div>
                                <div class="item">
                                    <div class="value">
                                        {{ lead.email }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.email)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Email</div>
                                </div>
                            </v-col>

                            <v-col cols="6">
                                <div class="subtitle-1 font-weight-light mb-5">
                                    Address
                                </div>

                                <div class="item">
                                    <div class="value">
                                        {{ lead.address_line_1 }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.address_line_1)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Address line 1</div>
                                </div>
                                <div class="item" v-show="!isBlank(lead.address_line_2)">
                                    <div class="value">
                                        {{ lead.address_line_2 }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.address_line_2)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Address line 2</div>
                                </div>

                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <div class="item">
                                            <div class="value">
                                                {{ lead.address_city }}
                                                <v-skeleton-loader type="text" v-show="isBlank(lead.address_city)" boilerplate max-width="75"></v-skeleton-loader>
                                            </div>
                                            <div class="label">City</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="item">
                                            <div class="value">
                                                {{ lead.address_state }}
                                                <v-skeleton-loader type="text" v-show="isBlank(lead.address_state)" boilerplate max-width="50"></v-skeleton-loader>
                                            </div>
                                            <div class="label">State</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="item">
                                            <div class="value">
                                                {{ lead.address_zipcode }}
                                                <v-skeleton-loader type="text" v-show="isBlank(lead.address_zipcode)" boilerplate max-width="65"></v-skeleton-loader>
                                            </div>
                                            <div class="label">Zipcode</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="6">
                        <div class="subtitle-1 font-weight-light mb-5">
                            Event Information
                        </div>

                        <v-row no-gutters>
                            <v-col cols="6">
                                <div class="item">
                                    <div class="value">
                                        {{ lead.event_type }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.event_type)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Event Type</div>
                                </div>
                                <div class="item">
                                    <div class="value">
                                        {{ formatDate( lead.event_date ) }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.event_date)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Event Date</div>
                                </div>
                            </v-col>

                            <v-col cols="6">
                                <div class="item">
                                    <div class="value">
                                        {{ lead.event_name }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.event_name)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Event Name</div>
                                </div>
                                <div class="item">
                                    <div class="value">
                                        {{ lead.event_location }}
                                        <v-skeleton-loader type="text" v-show="isBlank(lead.event_location)" boilerplate max-width="75"></v-skeleton-loader>
                                    </div>
                                    <div class="label">Event Location</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <div class="subtitle-1 font-weight-light my-5">
                    Additional Information
                </div>
                <v-row no-gutters>
                    <v-col cols="4">
                        <div class="item">
                            <div class="value">
                                {{ lead.school }}
                                <v-skeleton-loader type="text" v-show="isBlank(lead.school)" boilerplate max-width="75"></v-skeleton-loader>
                            </div>
                            <div class="label">School Name</div>
                        </div>

                        <div class="item">
                            <div class="value">
                                {{ lead.grade }}
                                <v-skeleton-loader type="text" v-show="isBlank(lead.grade)" boilerplate max-width="75"></v-skeleton-loader>
                            </div>
                            <div class="label">Grade</div>
                        </div>
                    </v-col>

                    <v-col cols="4">
                        <div class="item">
                            <div class="value">
                                {{ formatDate( lead.date_captured ) }}
                                <v-skeleton-loader type="text" v-show="isBlank(lead.date_captured)" boilerplate max-width="75"></v-skeleton-loader>
                            </div>
                            <div class="label">Lead Capture Date</div>
                        </div>

                        <div class="item">
                            <div class="value">
                                {{ lead.event_lead }}
                                <v-skeleton-loader type="text" v-show="isBlank(lead.event_lead)" boilerplate max-width="75"></v-skeleton-loader>
                            </div>
                            <div class="label">Lead Capture Event</div>
                        </div>
                    </v-col>

                    <v-col cols="4">
                        <div class="item">
                            <div class="value">
                                {{ lead.comments }}
                                <v-skeleton-loader type="text" v-show="isBlank(lead.comments)" boilerplate max-width="75"></v-skeleton-loader>
                            </div>
                            <div class="label">Comments</div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { API } from '@/inc/api';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    props: {
        leadId: { type: [String, Number], default: 0 }
    },
    data: () => ({
        lead        : null,
        loading     : false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();
            
            try {
                this.loading = true;
                let res = await api.get(`/admin/lead/${this.leadId}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.lead = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        formatDate(d, f = 'YYYY-MM-DD') {
            if( _st.isNUE( d ) )
                return '';

            return moment(d).format(f);
        },
        isBlank(v) {
            if( _st.isNUE(v) )
                return true;

            return false;
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    padding: 10px 0;    
}
.item .value {
    color: #000;
    font-size: 15px;
    min-height: 22px;
}
.item .label {
    font-size: 12px;
}
</style>